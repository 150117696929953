'use client';

// import * as Sentry from '@sentry/nextjs';
import Error from 'next/error';
// import { useEffect } from 'react';

export default function GlobalError(props: {
  error: Error & { digest?: string };
  params: { locale: string };
}) {
  // useEffect(() => {
  //   Sentry.captureException(props.error);
  // }, [props.error]);

  return (
    <html lang={props.params.locale}>
      <body>
        {/* This is the default Next.js error component but it doesn't allow omitting the statusCode property yet. */}
        <Error statusCode={200} />
      </body>
    </html>
  );
}
